
.titleProgileLogOutNotiBox {
   /* 
   right : 40;
   

    @media (min-midth : 300px){
        right : 20%;
    }

    @media (min-midth : 700px){
        right : 40%;
    }
    @media (min-midth : 1392px){
        right : 40px;
    }
    */

    /*

    background : blue;   

    @media (min-midth : 40em){
        background : lightblue;
    }

    @media (min-midth : 60em){
        background : red;
    }

    @media (min-midth : 80em){
        background : green;
    }
    */
}

.titleProgileLogOutNotiBox {
    background : transparent;
    right : 0px;
}


@media screen and (min-width: 100px) { /* if using min-width : screen > min-with  or using max-width :  screen < max-width   */
    .titleProgileLogOutNotiBox {
        /* display: block; */ /* cancel flex */
        /*
            
            background : blue;
        */
        
        right : 5px;
        position : absolute ;
    }
}

@media screen and (min-width: 400px) {
    .titleProgileLogOutNotiBox {
        /* display: block; */ /* cancel flex */
        right : 0px;
        /*
            background : rgb(233, 165, 38);
        */
        position : absolute ;
    }
}

@media screen and (min-width: 500px) {
    .titleProgileLogOutNotiBox {
        /* display: block; */ /* cancel flex */
        right : 0px;
        /*
            background : rgb(233, 165, 38);
        */
        position : absolute ;
    }
}


@media screen and (min-width: 501px) {
    .titleProgileLogOutNotiBox {
        /* display: block; */ /* cancel flex */
        right : 60px;
        /*
            background : green;
        */
        position : absolute ;
    }
}

@media screen and (min-width: 1000px) {
    .titleProgileLogOutNotiBox {
        /* display: block; */ /* cancel flex */
        right : 60px;
        /* 
            background : purple;  
        */
        position : absolute ;
    }
}


